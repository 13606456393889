var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper-multi-options",attrs:{"data-testid":"global-component-multi-options"}},[_c('div',{class:[
      'field-multi-options',
      { 'rounded-md': !_vm.showOptions, error: _vm.isError },
      {'no-border': _vm.showOptions}
    ],attrs:{"data-testid":"global-component-multi-options__event-show-option"},on:{"click":_vm.onShowOptions}},[_c('div',{class:[
        'value-multi-options',
        { hasValue: Boolean(_vm.valueMultiOptions) } ]},[_vm._v(" "+_vm._s(_vm.valueMultiOptions ? _vm.valueMultiOptions : _vm.placeholder)+" ")]),_c('div',{class:[
        'chevron-icon',
        { isActive: _vm.showOptions }
      ]},[_c('icon-chevron',{attrs:{"width":"20","height":"20","fill":"#A7A8AA"}})],1)]),(_vm.showOptions)?_c('div',{staticClass:"multi-options",attrs:{"data-testid":"global-component-multi-options__list"}},[(_vm.totalColumn >= 1)?_c('options',{attrs:{"id":"options-1","model-value":_vm.modelValue[0],"is-loading":_vm.loadings[0],"options":_vm.options1,"hide-chevron":_vm.totalColumn === 1},on:{"on-select-options":function (id) { return _vm.onSelectOptions(id, 1, 0); }}}):_vm._e(),(_vm.totalColumn >= 2)?_c('options',{attrs:{"id":"options-2","model-value":_vm.modelValue[1],"is-loading":_vm.loadings[1],"options":_vm.options2,"hide-chevron":_vm.totalColumn === 2},on:{"on-select-options":function (id) { return _vm.onSelectOptions(id, 2, 1); }}}):_vm._e(),(_vm.totalColumn >= 3)?_c('options',{attrs:{"id":"options-3","model-value":_vm.modelValue[2],"is-loading":_vm.loadings[2],"options":_vm.options3,"hide-chevron":_vm.totalColumn === 3},on:{"on-select-options":function (id) { return _vm.onSelectOptions(id, 3, 2); }}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
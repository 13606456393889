import { INIT_HEADER_TABLE_VARIANT } from '@/app/infrastructures/misc/Constants/product'
import ProductController from '@/app/ui/controllers/ProductController'
import { VariantList, VariantProduct } from '@/domain/entities/Product'
import { ProductVariantRequest } from '@/data/payload/contracts/ProductRequest'

export interface PropsTableData {
  data: number
  id: number
  index: number
  indexField: number
}

export interface FormVariantTypes {
  name: string
  value: string[]
  onHeader: boolean
}

export interface VariantListData {
  listVariant: ProductVariantRequest[]
  deletedListIDs: number[]
}

interface ProductVariantState {
  setHeaderOnWatch: (data: FormVariantTypes[]) => void
  setFormVariant: (
    data: FormVariantTypes[],
    dataListTable: (string | number | boolean | undefined)[][]
  ) => void
  onChangeTableVariant: (
    val: number | string | boolean,
    propsData: PropsTableData
  ) => void
  setValidateTable: (
    data: Array<Array<string | number | boolean | undefined>>,
    formVariant: FormVariantTypes[],
    isForEdit?: boolean
  ) => Array<boolean[]>
  makeDataTable: (
    productVariant: VariantProduct[],
    variantList: VariantList[]
  ) => void
  generateVariantList: (
    dataListTable: (string | number | boolean | undefined)[][],
    currentVariantName: FormVariantTypes[],
    previousVariant?: VariantProduct[],
    previousVariantName?: VariantList[]
  ) => VariantListData
}

const useActionProductVariant = (): ProductVariantState => {
  const controller = ProductController

  const onChangeTableVariant = (
    val: number | string | boolean,
    propsData: PropsTableData
  ) => {
    const { index, indexField } = propsData
    controller.setFieldTableVariant({ val, index, indexField })
    const channelData = controller.dataListTable[index]
    channelData[indexField] = val
    const result = controller.dataListTable.map((list, idx) => {
      if (idx === index) {
        return channelData
      } else {
        return list
      }
    })
    controller.setDataListTable(result)
  }

  const setHeaderOnWatch = (data: FormVariantTypes[]) => {
    const nameHasValue: string[] = []
    data.forEach((v: FormVariantTypes) => {
      if (v.value.length !== 0) {
        nameHasValue.push(v.name || '-')
      }
    })
    const newHeader = [...nameHasValue, ...INIT_HEADER_TABLE_VARIANT]
    controller.setHeaderTableVariant(newHeader)
  }

  const setFormVariant = (
    data: FormVariantTypes[],
    dataListTable: (string | number | boolean | undefined)[][]
  ) => {
    // Set header table variant
    setHeaderOnWatch(data)

    const lastLength = data.length - 1
    if (
      data.length !== 0 &&
      data[lastLength].value.length === 1 &&
      !data[lastLength].onHeader
    ) {
      data[lastLength].onHeader = true
    }

    if (data && data.length !== 0) {
      const newData: Array<Array<string | number | boolean | undefined>> = []
      let newId = 0
      const initRowValue = [undefined, undefined, undefined, undefined, false]

      // Set init body Table Variant
      const dataVariant1 = data[0]
      const dataVariant2 = data[1]
      dataVariant1.value.forEach(variant1 => {
        if (data.length > 1 && data[data.length - 1].value.length >= 1) {
          dataVariant2.value.forEach(variant2 => {
            newId += 1
            const variantItem = [newId, variant1, variant2, ...initRowValue]
            newData.push(variantItem)
          })
        } else {
          newId += 1
          newData.push([newId, variant1, ...initRowValue])
          if (
            data.length > 1 &&
            data[data.length - 1].value.length === 0 &&
            data[data.length - 1].onHeader
          ) {
            data[data.length - 1].onHeader = false
          }
        }
      })

      // Re-Mapping to include pre-existing values
      // Set Data Table
      const result = newData?.map(
        (item: Array<string | number | boolean | undefined>, index: number) => {
          const typeNewVariant = `${item[1]}-${item[2]}`
          const currentVariant = dataListTable.filter(dataList => {
            if (data && data[1]?.value.length > 0) {
              if (dataList.length === 8) {
                const typeOldVariant = `${dataList[1]}-${dataList[2]}`
                return typeOldVariant === typeNewVariant
              }
            } else {
              if (dataList.length === 8) {
                const typeOldVariant = `${dataList[1]}-${dataList[2]}`
                return typeOldVariant === typeNewVariant
              } else {
                return item[1] === dataList[1]
              }
            }
          })

          if (currentVariant.length > 0) {
            const [, ...restCurrent] = currentVariant[0]
            return [index + 1, ...restCurrent]
          }
          return item
        }
      )
      controller.setDataListTable(result)
    }
  }

  const setValidateTable = (
    data: Array<Array<string | number | boolean | undefined>>,
    formVariant: FormVariantTypes[],
    isForEdit?: boolean
  ): Array<boolean[]> => {
    const rowsError = Array<boolean[]>()

    data.forEach(list => {
      const rows = <boolean[]>[]
      list.forEach(
        (value: string | number | boolean | undefined, idx: number) => {
          const isRequiredMin0 =
            value === undefined || value === '' || value <= 0
          const isEmpty = value === undefined || value === ''
          if (
            (formVariant && formVariant.length === 1) ||
            (formVariant &&
              formVariant.length === 2 &&
              formVariant[1].value.length === 0)
          ) {
            if ([2, 3, 5].includes(idx)) {
              let validateData = Boolean(isRequiredMin0)
              if (isForEdit && idx === 3) {
                validateData = Boolean(isEmpty)
              }
              rows.push(validateData)
            } else {
              rows.push(false)
            }
          } else if (
            formVariant &&
            formVariant.length === 2 &&
            formVariant[1].value.length > 0
          ) {
            if ([3, 4, 6].includes(idx)) {
              let validateData = Boolean(isRequiredMin0)
              if (isForEdit && idx === 4) {
                validateData = Boolean(isEmpty)
              }
              rows.push(validateData)
            } else {
              rows.push(false)
            }
          } else {
            rows.push(false)
          }
        }
      )
      rowsError.push(rows)
    })
    return rowsError
  }

  // for edit
  const makeDataTable = (
    productVariant: VariantProduct[],
    variantList: VariantList[]
  ) => {
    const newDataListTable = productVariant.map(
      (val: VariantProduct, index) => {
        const variants = val.variants ? val.variants : []
        return [
          index + 1,
          ...variants,
          val.price,
          val.stock,
          val.sku,
          val.weight,
          val.isActive,
        ]
      }
    )
    controller.setDataListTable(newDataListTable)
    controller.setVariant(variantList)
  }

  const setDeletedIDsVariant = (
    dataListTable: (string | number | boolean | undefined)[][],
    currentVariantName: FormVariantTypes[],
    previousVariant: VariantProduct[],
    previousVariantName: VariantList[],
    listVariant: ProductVariantRequest[]
  ): number[] => {
    const listPreviousName = previousVariantName.map(v => v.key).join('-')
    const currentName = []
    const deletedVariantIDs: number[] = []

    if (listVariant.length > 0) {
      const getNames = [
        listVariant[0].variant_name_1,
        listVariant[0].variant_name_2,
      ]
      for (const key of getNames) {
        if (key) {
          currentName.push(key)
        }
      }
    }

    const listCurrentName = currentName.join('-')

    previousVariant.forEach((oldVariant, oldIndex) => {
      const oldListVariant = oldVariant.variants ? oldVariant.variants : []
      const oldVariantID = oldVariant.variantId ? oldVariant.variantId : -1
      const valueOldVariant = oldListVariant.join('-')
      let notSame = false

      dataListTable.forEach((valueTableVariant, index) => {
        const variantName: string[] = []
        if (currentVariantName.length === 1 && valueTableVariant.length === 7) {
          variantName.push(<string>valueTableVariant[1])
        } else if (
          currentVariantName.length === 2 &&
          valueTableVariant.length === 8
        ) {
          variantName.push(
            <string>valueTableVariant[1],
            <string>valueTableVariant[2]
          )
        }

        const currentValueVariant = variantName.join('-')
        if (
          valueOldVariant === currentValueVariant &&
          listCurrentName === listPreviousName
        ) {
          listVariant[index].product_id = oldVariantID
          notSame = true
          if (oldIndex !== index) {
            notSame = false
            delete listVariant[index].product_id
          }
        }
      })

      if (!notSame) {
        deletedVariantIDs.push(oldVariantID)
      }
    })
    return deletedVariantIDs
  }

  const generateVariantList = (
    dataListTable: (string | number | boolean | undefined)[][],
    currentVariantName: FormVariantTypes[],
    previousVariant?: VariantProduct[],
    previousVariantName?: VariantList[]
  ): VariantListData => {
    const listVariant: ProductVariantRequest[] = []
    dataListTable.forEach(valueTableVariant => {
      if (currentVariantName.length === 1 && valueTableVariant.length === 7) {
        listVariant.push({
          variant_name_1: <string>currentVariantName[0].name,
          variant_value_1: <string>valueTableVariant[1],
          price: <number>Number(valueTableVariant[2]),
          stock: <number>Number(valueTableVariant[3]),
          sku: <string>valueTableVariant[4] || '',
          weight: <number>Number(valueTableVariant[5]),
          is_active: <boolean>valueTableVariant[6],
        })
      } else if (
        currentVariantName.length === 2 &&
        valueTableVariant.length === 8
      ) {
        listVariant.push({
          variant_name_1: <string>currentVariantName[0].name,
          variant_value_1: <string>valueTableVariant[1],
          variant_name_2: <string>currentVariantName[1].name,
          variant_value_2: <string>valueTableVariant[2],
          price: <number>Number(valueTableVariant[3]),
          stock: <number>Number(valueTableVariant[4]),
          sku: <string | undefined>valueTableVariant[5] || '',
          weight: <number>Number(valueTableVariant[6]),
          is_active: <boolean>valueTableVariant[7],
        })
      }
    })

    // for edit
    let deletedListIDs: number[] = []
    if (previousVariant) {
      deletedListIDs = setDeletedIDsVariant(
        dataListTable,
        currentVariantName,
        previousVariant,
        previousVariantName || [],
        listVariant
      )
    }

    return { listVariant, deletedListIDs }
  }

  return {
    setHeaderOnWatch,
    setFormVariant,
    onChangeTableVariant,
    setValidateTable,
    makeDataTable,
    generateVariantList,
  }
}

export default useActionProductVariant

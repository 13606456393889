import {
  CreateProductRequestInterface,
  ProductStatus,
  ProductVariantRequest,
  UpdateProductRequestInterface,
  UpdateProductStatusRequestInterface,
  UploadFileProductBulkRequestInterface,
} from '@/data/payload/contracts/ProductRequest'

export class CreateProductRequest implements CreateProductRequestInterface {
  public merchantId?: number
  public productName?: string
  public salesPrice?: number
  public basePrice?: number
  public images?: Array<Blob | null>
  public stock?: number
  public weight?: number
  public length?: number
  public width?: number
  public height?: number
  public description?: string
  public information?: string
  public sku?: string
  public variantList?: ProductVariantRequest[]
  private readonly category?: number[]

  constructor(
    merchantId?: number,
    productName?: string,
    salesPrice?: number,
    basePrice?: number,
    images?: Array<Blob | null>,
    stock?: number,
    weight?: number,
    length?: number,
    width?: number,
    height?: number,
    description?: string,
    information?: string,
    sku?: string,
    variantList?: ProductVariantRequest[],
    category?: number[]
  ) {
    this.merchantId = merchantId
    this.productName = productName
    this.salesPrice = salesPrice
    this.basePrice = basePrice
    this.images = images
    this.stock = stock
    this.weight = weight
    this.length = length
    this.width = width
    this.height = height
    this.description = description
    this.information = information
    this.sku = sku
    this.variantList = variantList
    this.category = category
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('merchant_id', String(this.merchantId))
    data.append('product_name', this.productName as string)
    data.append('price', String(this.salesPrice))
    data.append('base_price', String(this.basePrice))
    data.append('weight', String(this.weight))
    data.append('length', String(this.length))
    data.append('width', String(this.width))
    data.append('height', String(this.height))
    data.append('description', this.description as string)
    data.append('information', this.information as string)
    data.append('stock', String(this.stock))
    data.append('sku', String(this.sku))
    data.append('variant_list', JSON.stringify(this.variantList))

    this.images?.forEach((image: Blob | null, index) => {
      if (!image) return
      const fieldName = index == 0 ? 'product_image' : `image_pdp_${index}`
      data.append(fieldName, image)
    })

    if (this.category?.length === 3) {
      data.append('product_type_3rd_id', String(this.category[2]))
    }

    return data
  }
}

export class UpdateProductRequest implements UpdateProductRequestInterface {
  public merchantId?: number
  public productName?: string
  public salesPrice?: number
  public basePrice?: number
  public images?: Array<Blob | null>
  public stock?: number
  public weight?: number
  public length?: number
  public width?: number
  public height?: number
  public description?: string
  public information?: string
  public deletedImages?: number[]
  public sku?: string
  private readonly variantList?: ProductVariantRequest[]
  private readonly deletedVariantIds?: number[]
  private readonly category?: number[]

  constructor(
    merchantId?: number,
    productName?: string,
    salesPrice?: number,
    basePrice?: number,
    images?: Array<Blob | null>,
    stock?: number,
    weight?: number,
    length?: number,
    width?: number,
    height?: number,
    description?: string,
    information?: string,
    deletedImages?: number[],
    sku?: string,
    variantList?: ProductVariantRequest[],
    deletedVariantIds?: number[],
    category?: number[]
  ) {
    this.merchantId = merchantId
    this.productName = productName
    this.salesPrice = salesPrice
    this.basePrice = basePrice
    this.images = images
    this.stock = stock
    this.weight = weight
    this.length = length
    this.width = width
    this.height = height
    this.description = description
    this.information = information
    this.deletedImages = deletedImages
    this.sku = sku
    this.variantList = variantList
    this.deletedVariantIds = deletedVariantIds
    this.category = category
  }

  public toPayload(): FormData {
    const data = new FormData()
    data.append('merchant_id', String(this.merchantId))
    data.append('product_name', this.productName as string)
    data.append('price', String(this.salesPrice))
    data.append('base_price', String(this.basePrice))
    data.append('weight', String(this.weight))
    data.append('length', String(this.length))
    data.append('width', String(this.width))
    data.append('height', String(this.height))
    data.append('description', this.description as string)
    data.append('information', this.information as string)
    data.append('stock', String(this.stock))
    data.append('sku', String(this.sku))
    data.append('variant_list', JSON.stringify(this.variantList))
    data.append('deleted_variant_ids', JSON.stringify(this.deletedVariantIds))

    // if there are deleteImages
    if (this.deletedImages?.length) {
      data.append('deleted_image', this.deletedImages.join(','))
    }

    this.images?.forEach((image: Blob | null, index) => {
      if (!image) return
      const fieldName = index == 0 ? 'product_image' : `image_pdp_${index}`
      data.append(fieldName, image)
    })

    if (this.category?.length === 3) {
      data.append('product_type_3rd_id', String(this.category[2]))
    }

    return data
  }
}

export class UpdateProductStatusRequest
  implements UpdateProductStatusRequestInterface {
  public isActive: boolean

  constructor(isActive: boolean) {
    this.isActive = isActive
  }

  public toPayload(): ProductStatus {
    return {
      is_active: this.isActive,
    }
  }
}

export class UploadFileProductBulkRequest
  implements UploadFileProductBulkRequestInterface {
  public file?: File

  constructor(file?: File) {
    this.file = file
  }

  public toPayload(): FormData {
    // Specific for form data, this toPayload() will actually convert them into FormData
    const data = new FormData()
    data.append('file', this.file as File)
    return data
  }
}

import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class MixinValidation extends Vue {
  setErrorDimension(
    errLength: boolean,
    errWidth: boolean,
    errHeight: boolean
  ): string {
    if (errLength || errWidth || errHeight) {
      const errs: { name: string; err: boolean }[] = [
        {
          name: 'Length',
          err: errLength,
        },
        {
          name: 'Width',
          err: errWidth,
        },
        {
          name: 'Height',
          err: errHeight,
        },
      ]

      const errName: string[] = []
      for (const { name, err } of errs) {
        if (err) {
          errName.push(name)
        }
      }

      return 'Required ' + errName.join(', ') + ' more than 0'
    }

    return ''
  }
}








































import { Component, Prop, Vue } from 'vue-property-decorator'
import DropzoneIcon from '@/app/ui/assets/dropzone_icon.vue'
import DropzoneCloseIcon from '@/app/ui/assets/dropzone_close_icon.vue'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {
    DropzoneIcon,
    DropzoneCloseIcon,
  },
})

/**
 * description: string
 * Show input description
 *
 * preview: string
 * Default image preview
 *
 * maxFileSize: number
 * Max file size in KB
 *
 * text: string
 * Text that is displayed when idle
 *
 * dropText: string
 * Text that is displayed when the file dragged
 *
 */
export default class Dropzone extends Vue {
  @Prop({ type: String }) private description?: string
  @Prop({ type: String, default: '' }) private preview?: string
  @Prop({ type: String }) private accept?: string
  @Prop({ type: Number, default: 1 }) private maxFileSize!: number // in KB
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: 'Drop Image' }) private dropText!: string

  fileName: string | null = null
  base64Preview: string | null = null
  dragOver = false
  error = ''

  get imagePreview(): string {
    if (this.base64Preview) {
      return this.base64Preview
    }

    return <string>this.preview
  }

  get getText(): string {
    if (this.dragOver) {
      return this.dropText
    }

    return this.text
  }

  private onFileChange(files: FileList) {
    try {
      if (files) {
        if (this.accept && !this.accept.split(',').includes(files[0].type)) {
          throw new Error(`File Type must be in ${this.accept}`)
        }

        if (files[0].size / 1024 > this.maxFileSize) {
          throw new Error(`File size exceeds ${this.maxFileSize}KB`)
        }

        this.error = ''
        this.fileName = files[0].name

        // file reader
        const reader = new FileReader()
        reader.onload = e => {
          this.base64Preview = <string>e.target?.result
        }
        reader.readAsDataURL(files[0])
        // end of file reader

        this.$emit('input', files[0])
      }
    } catch (error) {
      if (error instanceof Error) {
        this.error = error.message

        // Show error alert
        this.$notify({
          title: 'Error',
          text: error.message,
          type: 'error',
          duration: 3000,
        })
      }
    }
  }

  private drop({ dataTransfer }: { dataTransfer: DataTransfer }) {
    this.dragOver = false
    this.$emit('change', dataTransfer)
    this.onFileChange(dataTransfer?.files)
  }

  private onInputFileChange(event: Event) {
    const target = <HTMLInputElement>event.target
    this.$emit('change', target.files)
    if (target.files) this.onFileChange(target.files)
  }

  private removeImage(e: Event) {
    e.preventDefault()
    if (this.base64Preview) {
      this.base64Preview = ''
      this.fileName = ''
      this.$emit('input', null)
    } else {
      this.$emit('delete', this.$attrs.id)
    }
  }

  private dragover() {
    this.dragOver = true
  }

  private dragleave() {
    this.dragOver = false
  }
}





































































import { Component, Vue, Prop } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import TrashModalIcon from '@/app/ui/assets/icon_trash_modal.vue'
import CloseCircleIcon from '@/app/ui/assets/close_circle_icon_modal.vue'
import ErrorIcon from '@/app/ui/assets/illustration_confirmation_delete.vue'
import InfoIcon from '@/app/ui/assets/icon_info_blur.vue'
import IconSpinner from '@/app/ui/assets/ic_spinner.vue'

@Component({
  components: {
    Modal,
    Button,
    ConfirmationIcon,
    TrashModalIcon,
    CloseCircleIcon,
    ErrorIcon,
    InfoIcon,
    IconSpinner,
  },
})
export default class ModalAction extends Vue {
  @Prop({ default: 'Title' }) private title!: string
  @Prop({ default: 'Description' }) private description!: string
  @Prop({ default: 'Yes' }) private textPrimary!: string
  @Prop({ default: 'No' }) private textSecondary!: string
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: '' }) private icon!: string
  @Prop({ default: false }) private useSingleButton!: boolean
  @Prop({ default: false }) private showCloseIcon!: boolean
  @Prop({ default: false }) private buttonFull!: boolean
  @Prop({ default: false }) private isLoading!: boolean

  private onCancel() {
    this.$emit('cancel')
  }

  private onAction() {
    this.$emit('action')
  }

  private isHasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }
}























import { Component, Prop, Vue } from 'vue-property-decorator'
import MultiOptions from '@/app/ui/components/MultiOptions/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  components: {
    MultiOptions,
    IconWarningTriangle,
  }
})

export default class MultiOptionProduct extends Vue {
  @Prop({ type: String, default: '' }) label!: string
  @Prop({ type: String, default: '' }) subLabel!: string
  @Prop({ type: Boolean, default: false }) required!: boolean
  @Prop({ type: Boolean, default: false }) isError!: boolean
  @Prop({ type: String, default: '' }) errorMessage!: string

}

































import { Component, Vue, Prop } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'

@Component({
  inheritAttrs: false,
  components: {
    TextInput,
    IconWarningTriangle
  },
})
export default class MerchantTextInput extends Vue {
  @Prop({ type: String }) private title!: string
  @Prop({ type: String }) private errorMessage!: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ type: Object }) private vuelidateObject!: Record<string, any>
  @Prop({ type: Boolean, default: false }) private required!: boolean
  @Prop({ type: String }) public alias!: string
  @Prop({ type: String, default: '' }) public description!: string

  private handleInput(e: string) {
    this.$emit('input', e)

    if (this.vuelidateObject) this.vuelidateObject.$touch()
  }

  get hasError(): boolean {
    return (
      this.vuelidateObject &&
      this.vuelidateObject.$invalid &&
      this.vuelidateObject.$dirty
    )
  }
}







































































































































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators'
import {validationMixin} from 'vuelidate'
import {
  alphaNum,
  decimal,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  requiredIf,
  ValidationRule,
} from 'vuelidate/lib/validators'
import MixinValidation from '@/app/ui/views/BisaBelanja/Merchant/Product/mixinValidation'
import MerchantTextInput from '../../components/MerchantTextInput/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Dropzone from '@/app/ui/components/Dropzone/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import IconPlus from '@/app/ui/assets/icon_plus.vue'
import AddIcon from '@/app/ui/assets/add_icon.vue'
import ProductTextInput from '../components/ProductTextInput/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import ProductTableVariant from '../components/ProductTableVariant/index.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import AddCircleIcon from '@/app/ui/assets/add_circle.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import controller, { productHasCategory } from '@/app/ui/controllers/ProductController'
import {Product, ProductCategory} from '@/domain/entities/Product'
import useActionProductVariant, {
  FormVariantTypes,
  PropsTableData,
} from '@/app/ui/views/BisaBelanja/Merchant/Product/useCase'
import MultiOptionsProduct from '../components/MultiOptions/index.vue'

interface Form {
  name: string
  salesPrice: number | null
  basePrice: number | null
  images: Array<Blob | null>
  stock: number | null
  weight: number | null
  length: number | null
  width: number | null
  height: number | null
  description: string
  information: string
  sku: string
  category: number[]
}

interface ValidationsInterface {
  form: {
    name: {
      required: () => ValidationRule,
      maxLength: ValidationRule
    },
    salesPrice: {
      required: ValidationRule,
      numeric: () => ValidationRule
    },
    basePrice: {
      required: ValidationRule,
      numeric: () => ValidationRule
    },
    images: {
      0: {
        required: ValidationRule,
      },
      1: {
        required: ValidationRule,
      },
      2: {
        required: boolean,
      },
      3: {
        required: boolean,
      }
    },
    stock: {
      [key: string]: unknown
    },
    sku: {
      maxLength: ValidationRule,
    },
    weight: {
      required: ValidationRule,
      decimal: () => ValidationRule,
    },
    length: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    width: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    height: {
      decimal: () => ValidationRule,
      minValue: ValidationRule,
    },
    description: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    information: {
      required: () => ValidationRule,
      maxLength: ValidationRule,
    },
    category: {
      required: () => ValidationRule,
      minLength: ValidationRule,
    }
  },
  formVariant: {
    required: ValidationRule,
    $each: {
      name: {
        required: ValidationRule,
        minLength: ValidationRule,
      },
      value: {
        required: ValidationRule,
        minLength: ValidationRule,
      }
    }
  }
}


@Component({
  mixins: [validationMixin, MixinValidation],
  components: {
    MerchantTextInput,
    Dropzone,
    TextInput,
    Button,
    Modal,
    LoadingOverlay,
    ProductTextInput,
    ProductTableVariant,
    ModalAction,
    MultiOptionsProduct,
    IconWarningTriangle,
    IconPlus,
    AddCircleIcon,
    AddIcon,
    TrashIcon,
  },
})
export default class CreateMerchantProduct extends Vue {
  controller = controller
  form: Form = {
    name: '',
    salesPrice: null,
    basePrice: null,
    images: [null, null, null, null],
    stock: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    description: '',
    information: '',
    sku: '',
    category: [],
  }
  confirmationModal = false
  successModal = false
  imagePreview: Array<string | null> = [null, null, null, null]
  deletedImages: number[] = []
  actionModalName = ''
  indexDel = -1
  isValidate = false
  loaderOfCategories = [true]

  options1: ProductCategory[] = []
  options2: ProductCategory[] = []
  options3: ProductCategory[] = []

  // Table Variant
  showModal = false
  actionVariant = useActionProductVariant()
  listErrorVariant = Array<boolean[]>([])
  hasDuplicateOptions = false
  errorMsgFormVariant = ['', '']

  created(): void {
    controller.getDetailAndCategories(this.$route.params.productId)
  }

  @Validations()
  validations(): ValidationsInterface {
    const stockValidator: Record<string, unknown> = {
      required: requiredIf(() => {
        return this.formVariant.length === 0
      }),
      numeric,
      alphaNum,
      minValue: minValue(0),
    }

    if (this.formVariant.length === 0) {
      stockValidator['maxValue'] = maxValue(9999)
    }

    return {
      form: {
        name: {
          required,
          maxLength: maxLength(100)
        },
        salesPrice: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          numeric
        },
        basePrice: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          numeric
        },
        images: {
          0: {
            required: requiredIf(() => this.deletedImages.includes(0)),
          },
          1: {
            required: requiredIf(() => this.deletedImages.includes(1)),
          },
          2: {
            required: false,
          },
          3: {
            required: false,
          },
        },
        stock: {...stockValidator},
        sku: { maxLength: maxLength(100) },
        weight: {
          required: requiredIf(() => {
            return this.formVariant.length === 0
          }),
          decimal
        },
        length: { decimal, minValue: minValue(1) },
        width: { decimal, minValue: minValue(1) },
        height: { decimal, minValue: minValue(1) },
        description: { required, maxLength: maxLength(3000) },
        information: { required, maxLength: maxLength(150) },
        category: { required, minLength: minLength(3) }
      },
      formVariant: {
        required: requiredIf(() => {
          return this.formVariant.length > 0
        }),
        $each: {
          name: {
            required: requiredIf(() => {
              return this.formVariant.length > 0
            }),
            minLength: minLength(1),
          },
          value: {
            required: requiredIf(() => {
              return this.formVariant.length > 0
            }),
            minLength: minLength(1),
          }
        }
      }
    }
  }

  private dropzoneLabel(index: number): string {
    if (index === 0) return 'Main*'

    return `Image ${index}${index === 1 ? '*' : ''}`
  }

  private updateProduct(): void {
    this.isValidate = true
    const hasErrorFormVariantName = this.errorMsgFormVariant.some(v => v.length > 0)
    const hasErrorTableVariant = this.listErrorVariant.flat().includes(true)
    if (this.$v.$invalid || hasErrorTableVariant || hasErrorFormVariantName) {
      this.confirmationModal = false
      this.$notify({
        title: 'Create Product Failed',
        text: 'Mohon cek kembali setiap kolom dan pastikan semua telah terisi',
        type: 'error',
        duration: 5000,
      })
    } else if (Utils.isIncludeEmoticon(this.form.sku)) {
      this.confirmationModal = false
      this.$notify({
        title: 'Update Product Failed',
        text: 'Kode SKU harus berisi huruf, angka dan simbol',
        type: 'error',
        duration: 5000,
      })
    } else {
      const {listVariant, deletedListIDs} =
        this.actionVariant.generateVariantList(
          this.dataListTable,
          this.formVariant,
          controller.productDetail.variantProductList,
          controller.productDetail.variantList,
      )
      controller.updateProduct({
        id: Number(this.$route.params.productId),
        merchantId: Number(this.$route.params.id),
        deletedImages: this.deletedImages,
        deletedVariantIds: deletedListIDs,
        variantList: listVariant,
        ...this.form,
        stock: this.formVariant.length > 0 ? 1 : this.form.stock,
      })
    }
  }

  private onCloseSuccessModal(): void {
    this.successModal = false
    this.$router.push({ name: 'MerchantProductListPage' })
  }

  @Watch('controller.productDetail', { deep: true })
  onProductDetailChanged(product: Product): void {
    this.form.name = <string>product.productName
    this.form.salesPrice = <number>product.salesPrice
    this.form.basePrice = <number>product.basePrice
    this.form.weight = <number>product.weight
    this.form.length = <number>product.length
    this.form.width = <number>product.width
    this.form.height = <number>product.height
    this.form.description = <string>product.description
    this.form.description = <string>product.description
    this.form.information = <string>product.information
    this.form.stock = <number>product.stock
    this.form.sku = <string>product.sku
    this.actionVariant.makeDataTable(
      product.variantProductList || [],
      product.variantList || [],
    )

    if (productHasCategory(product)) {
      this.form.category = [
        <number>product.productTypeId,
        <number>product.secondProductTypeId,
        <number>product.thirdProductTypeId
      ]
    }

    this.imagePreview = [
      <string>product.imageUrl,
      ...(<Array<string>>product.imagePDP),
    ]
  }

  private deleteImage(index: number): void {
    this.$set(this.imagePreview, index, null)
    this.deletedImages.push(index)
  }

  private changeImage(index: number): void {
    if (this.deletedImages.includes(index))
      this.deletedImages = this.deletedImages.filter(i => i !== index)
  }

  @Watch('form.images')
  onMainImageChanged(val: (File | null)[]): void {
    if (
      val[0] === null &&
      controller.productDetail.imageUrl &&
      this.imagePreview[0] === null
    ) {
      this.deletedImages.push(0)
    }

    val.slice(1).forEach((value, key) => {
      const index: number = key + 1

      if (
        val[index] === null &&
        controller.productDetail.imagePDP &&
        controller.productDetail.imagePDP[index] &&
        !this.imagePreview[index]
      ) {
        this.deletedImages.push(index)
      }
    })
  }

  // variant
  private getErrorVariantName(idx: number): boolean {
    const validateFormVariant = this.$v.formVariant as Record<string, any>
    if (
      validateFormVariant.$each[idx].name.$invalid && this.isValidate
    ) {
      this.errorMsgFormVariant[idx] = 'Variant name is required'
      return true
    } else if (this.errorMsgFormVariant[idx]) {
      return true
    } else {
      this.errorMsgFormVariant[idx] = ''
      return false
    }
  }

  private onInputVariantName(val: string, idx: number): void {
    const xIndex = idx === 0 ? 1 : 0
    this.errorMsgFormVariant = ['', '']
    if (this.formVariant && this.formVariant.length === 2) {
      if (
        this.formVariant[xIndex].name.toLowerCase() === val.toLowerCase() &&
        this.formVariant[xIndex].name !== ''
      ) {
        this.errorMsgFormVariant[idx] = 'Variant name cannot be the same'
      } else {
        this.errorMsgFormVariant[idx] = ''
      }
    } else {
      this.errorMsgFormVariant[idx] = ''
    }
  }

  private getErrorOptionsVariant(idx: number): boolean {
    const validateFormVariant = this.$v.formVariant as Record<string, any>
    return !this.hasDuplicateOptions &&
      validateFormVariant.$each[idx].value.$invalid &&
      this.isValidate;
  }

  private eventOptionVariant(): void {
    if (this.formVariant.length > 0) {
      this.showModal = true
      this.actionModalName = 'delete_all'
    } else {
      this.onAddVariant()
    }
  }

  private actionModal(): void {
    switch (this.actionModalName) {
      case 'delete_all':
        controller.clearAllVariant()
        this.showModal = false
        break
      default:
        if (this.indexDel !== -1) {
          controller.removeOneVariant(this.indexDel)
          this.showModal = false
        }
    }
  }

  private deleteOneVariant(idx: number): void {
    this.showModal = true
    this.indexDel = idx
    this.actionModalName = ''
  }

  private onAddVariant(): void {
    controller.setAddVariant({
      name: '',
      value: [],
      onHeader: false,
    })
  }

  private onChangeTableVariant(
    val: number | string | boolean,
    propsData: PropsTableData
  ): void {
    this.actionVariant.onChangeTableVariant(val, propsData)
  }

  private onSetStatusVariant(isActive: boolean, propsData: PropsTableData): void {
    this.actionVariant.onChangeTableVariant(!isActive, propsData)
  }

  get isDisabledVariant2(): boolean {
    return Boolean(
      controller.formVariant &&
      controller.formVariant[0].name &&
      controller.formVariant[0].value.length > 0
    )
  }

  get headerTableVariant(): string[] {
    return controller.headerTableVariant
  }

  get formVariant(): FormVariantTypes[] {
    return controller.formVariant
  }

  get dataListTable(): (string | number | boolean | undefined)[][] {
    return controller.dataListTable
  }

  private onSelectCategory(val: number, index: number): void {
    this.form.category[index] = val
    const threeCategoryFilled = this.form.category.length >= 3
    if (threeCategoryFilled) this.form.category.splice(index, 2, val)
    if (index === 0) {
      if (threeCategoryFilled) {
        this.form.category.splice(1, 2)
      }
      this.options3 = []
      this.loaderOfCategories[1] = true
      controller.getCategories({idOne: val})
    }

    if (index === 1) {
      if (threeCategoryFilled) {
        this.form.category.splice(2, 1)
      }
      this.loaderOfCategories[2] = true
      controller.getCategories({idOne: this.form.category[0], idTwo: val})
    }
  }

  @Watch('controller.errorFetch', { deep: true })
  onErrorFetchChanged(err: string): void {
    if (err !== '') {
      this.$notify({
        title: 'Get category list failed',
        text: err,
        type: 'error',
        duration: 5000,
      })
    }
  }

  @Watch('controller.optionsCategoryOne', { deep: true })
  setOptionsCategoryOneChanged(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[0] = false
    }
    this.options1 = data
  }

  @Watch('controller.optionsCategoryTwo', { deep: true })
  setOptionsCategoryTwo(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[1] = false
    }
    this.options2 = data
  }

  @Watch('controller.optionsCategoryThree', { deep: true })
  setOptionsCategoryThree(data: ProductCategory[]): void {
    if (data.length > 0) {
      this.loaderOfCategories[2] = false
    }
    this.options3 = data
  }

  @Watch('formVariant', { deep: true })
  setFormVariant(data: FormVariantTypes[]): void {
    this.actionVariant.setFormVariant(data, this.dataListTable)
  }

  @Watch('dataListTable', { deep: true })
  setDataListTable(data: (string | number | boolean | undefined)[][]): void {
    // Set init ListError
    this.listErrorVariant = this.actionVariant.setValidateTable(data, this.formVariant, true)
  }

  @Watch('controller.statusCreateUpdateProduct')
  onStatusCreateUpdateProductChanged(status: string): void {
    if (status !== '') {
      if (status === EventBusConstants.UPDATE_PRODUCT_SUCCESS) {
        this.confirmationModal = false
        this.successModal = true
      }
    }
  }

  beforeDestroy(): void {
    controller.setStatusCreateUpdateProduct('')
    controller.clearAllVariant()
  }
}












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'LoadingShimmerOptions',
})
export default class LoadingShimmerOptions extends Vue {
  @Prop({ required: false, default: 5, type: Number }) private count!: 5
}













































import { Component, Prop, Vue } from 'vue-property-decorator'
import CloseLineIcon from '@/app/ui/assets/close_line.vue'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  name: 'inputMultiTags',
  components: {
    CloseLineIcon
  }
})
export default class InputMultiTags extends Vue {
  @Prop({ type: String, default: 'input-tag' }) id!: string
  @Prop({ type: String, default: 'Variant options cannot be the same' }) errorMessageTag!: string
  @Prop({ type: Boolean, default: true }) canDelete!: boolean
  @Prop({ type: Boolean, default: true }) canEnter!: boolean
  @Prop({ type: Boolean, default: false }) hideErrorDuplicate!: boolean
  @Prop({ type: Boolean, default: false }) isError!: boolean
  @Prop({ type: String, default: '' }) formatTag!: 'uppercase' | 'lowercase' | ''
  @Prop({ type: String, default: 'text' }) type!: 'text' | 'number'

  textInput = ''
  errorMsg = ''

  get listTags(): string[] {
    return [...this.$attrs.value || '']
  }

  private onFocus() {
    const elInputRef = document.getElementById(this.id)
    if (elInputRef) {
      elInputRef.focus()
    }
  }

  private onKeyupEnter () {
    const modelValue = this.$attrs.value || []
    if (this.textInput) {
      const list = [...modelValue, this.formattedInputTag()]
      if (
        modelValue
          .toString()
          .toLowerCase()
          .split(',')
          .indexOf(this.textInput.toLowerCase()) < 0 &&
        this.canEnter &&
        !this.hideErrorDuplicate
      ) {
        this.textInput = ''
        this.$emit('update:modelValue', list)
        this.$emit('getError', false)
      } else {
        this.$emit('getError', true)
        this.errorMsg = this.errorMessageTag
      }
    }
  }

private onKeypress($event: {
    keyCode: number
    which: number
    preventDefault: () => void
    target: HTMLInputElement
  }) {
    if (this.type === 'number') {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
        $event.preventDefault()
      }
    }

  }


  private onInput(event: { target: { value: string } }) {
    this.errorMsg = ''
    const elInputRef = document.getElementById('input-tag')
    const OS = Utils.getOS()
    if (elInputRef && !['Mac OS', 'iOS'].includes(OS)) {
      elInputRef.setAttribute('size', String(this.textInput.length) || '1')
    }
    this.$emit('input', event.target.value)
  }

  private onDeleteTag(idx: number) {
    const filterDelete = this.listTags.filter((_, index) => index !== idx)
    this.$emit('update:modelValue', filterDelete)
  }

  private formattedInputTag(): string {
    if (this.formatTag === 'uppercase') {
      return this.textInput.toUpperCase()
    } else if (this.formatTag === 'lowercase') {
      return this.textInput.toLowerCase()
    }
    return this.textInput
  }
}

function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full overflow-x-auto"},[_c('DataTableV2',_vm._b({attrs:{"id-index":0,"hideIndex":[0],"headers":_vm.thValue,"data-items":_vm.dataListTable,"custom-name":_vm.customName,"requiredValue":_vm.requiredValue},scopedSlots:_vm._u([{key:_vm.slotVariantName1,fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',[_c('div',{staticClass:"text-gray-4 text-sm"},[_vm._v(_vm._s(props.data.value))]),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()])]}},{key:_vm.slotVariantName2,fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',[_c('div',{staticClass:"text-gray-4 text-sm"},[_vm._v(_vm._s(props.data.value))]),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()])]}},{key:"price",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"max-w-32-a"},[_c('ProductTextInput',{attrs:{"id":"price","type":"currency","name":"price","value":props.data.value,"customClass":"py-0.5","placeholder":"Rp.","allowPasteNumber":"","isError":_vm.cellHasError(props.index, props.indexField).error,"errorMessage":_vm.cellHasError(props.index, props.indexField).message},on:{"input":function (val) { return _vm.onInputField(val, props); }}}),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()],1)]}},{key:"stock",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"max-w-32-a"},[_c('ProductTextInput',{attrs:{"id":"stock","type":"number","name":"stock","maxNumber":9999,"value":props.data.value,"customClass":"py-0.5","placeholder":"Stock","allowPasteNumber":"","isError":_vm.cellHasError(props.index, props.indexField, 'stock').error,"errorMessage":_vm.cellHasError(props.index, props.indexField, 'stock').message},on:{"input":function (val) { return _vm.onInputField(val, props); }}}),(_vm.rowHasError(props.index, props.indexField, 'stock'))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()],1)]}},{key:"sku",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"max-w-32-a"},[_c('ProductTextInput',{attrs:{"id":"sku","type":"text","name":"sku","value":props.data.value,"customClass":"py-0.5","maxLength":100,"placeholder":"SKU","allowPasteNumber":""},on:{"input":function (val) { return _vm.onInputField(val, props); }}}),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()],1)]}},{key:"weight-gram",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"max-w-32-a"},[_c('ProductTextInput',{attrs:{"id":"weight","type":"number","name":"weight","value":props.data.value,"customClass":"py-0.5","placeholder":"Weight","allowPasteNumber":"","isError":_vm.cellHasError(props.index, props.indexField).error,"errorMessage":_vm.cellHasError(props.index, props.indexField).message},on:{"input":function (val) { return _vm.onInputField(val, props); }}}),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()],1)]}},{key:"status",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var props = rest;
return [_c('div',{staticClass:"max-w-32-a"},[_c('Toggle',{attrs:{"id":props.id.value,"checked":props.data.value,"circle-height-class":"h-3","circle-width-class":"w-3","body-height-class":"h-5","body-width-class":"w-8","body-active-class":"bg-bgGreen","body-inactive-class":"bg-gray-2","body-border-class":"border-0","circle-active-class":"bg-white","circle-inactive-class":"bg-white"},on:{"click":function () { _vm.onSetStatusVariant(props) }}}),(_vm.rowHasError(props.index, props.indexField))?_c('div',{staticClass:"h-6 mt-2"}):_vm._e()],1)]}},{key:"data-empty",fn:function(){return [_c('EmptyState',{attrs:{"customClass":"w-full max-w-125 mx-auto flex flex-col justify-center items-center gap-2 my-24","type":"variant-product","text":"Let's Fill Product Variants First!","description":"Here you can add product variants and begin setting your price, stock, SKU, and product weight.","classText":"text-sm","classDesc":"text-center mt-2 text-gray-1 text-sm"}})]},proxy:true}],null,true)},'DataTableV2',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const MODAL_PRODUCTS = {
  activate: {
    title: 'Active Product?',
    description: 'Are you sure you want to activate this product?'
  },
  deactivate: {
    title: 'Deactive Product?',
    description: 'Are you sure you want to deactivate this product?'
  },
  delete: {
    title: 'Delete Product?',
    description: 'Are you sure you want to delete this product?'
  },
}

export const MODAL_VARIANT_PRODUCT = {
  activate: {
    title: 'Active Variant Product?',
    description: 'Are you sure you want to activate this variant product?',
  },
  deactivate: {
    title: 'Deactive Variant Product?',
    description: 'Are you sure you want to deactivate this variant product?',
  }
}

export const HEADER_LIST_PRODUCTS = [
  {
    title: 'ID',
    class: '',
    customStyle: { maxWidth: '70px'}
  },
  {
    title: 'Product Name',
    class: '',
    customStyle: { minWidth: '250px', maxWidth: '250px', paddingRight: '4vw' }
  },
  {
    title: 'Price',
    class: '',
    customStyle: { minWidth: '220px', maxWidth: '220px' }
  },
  {
    title: 'Stock',
    class: '',
    customStyle: { minWidth: '150px', maxWidth: '150px' }
  },
  {
    title: 'Active',
    class: '',
    customStyle: { minWidth: '126px' }
  },
  {
    title: 'Action',
    class: 'text-center',
  },
]

export enum EnumForm {
  CREATE = 'create',
  EDIT = 'edit',
  DETAIL = 'detail',
}

export const INIT_HEADER_TABLE_VARIANT: string[] = [
  'Price',
  'Stock',
  'SKU',
  'Weight (gram)',
  'Status',
]






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { OptionsTypes } from '../index.vue'
import LoadingShimmerOptions from '../LoadingShimmer/index.vue'
import IconChevron from '@/app/ui/assets/expand_icon.vue'

@Component({
  components: {
    LoadingShimmerOptions,
    IconChevron
  }
})
export default class Options extends Vue {
  @Prop({ type: String, default: 'options' }) id!: string
  @Prop({ type: Boolean, default: false }) hideChevron!: boolean
  @Prop({ type: Number, default: 0 }) modelValue!: number
  @Prop({ type: Boolean, default: false }) isLoading!: boolean
  @Prop({ type: Array, default: () => []}) options!: OptionsTypes[]

  private onSelectOptions(idOption: number | string): void {
    this.$emit('on-select-options', idOption)
  }

}

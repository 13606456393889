



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import InputMultiTags from '@/app/ui/components/InputMultiTags/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'
import IconWarningTriangle from '@/app/ui/assets/icon_warning_triangles.vue'
import { FormVariantTypes } from '../../useCase'

@Component({
  inheritAttrs: false,
  components: {
    TextInput,
    InputMultiTags,
    TrashIcon,
    IconWarningTriangle
  },
})
export default class ProductTextInput extends Vue {
  @Prop({ default: '', required: false }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: '' }) private customClass!: string
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: 'text' }) private type!: string
  @Prop({ default: false }) private isMultiTag!: boolean
  @Prop({ default: false }) private hideErrorDuplicate!: boolean
  @Prop({ default: false }) private canDelete!: boolean
  @Prop({ default: () => [] }) private formVariant!: FormVariantTypes[]
  @Prop({ type: String }) public alias!: string
  @Prop({ type: String, default: '' }) public labelClass!: string

  isDuplicateOptions = false

  private onInputMultiTags(val: string) {
    this.isDuplicateOptions = false
    const listTags = this.formVariant.map(tags => {
      let rows = <string[]>([])
      tags.value.forEach((tag) => {
        rows.push(tag.toLowerCase())
      })
      return rows
    }).flat()
    this.isDuplicateOptions = listTags.includes(val.toLowerCase());
    this.$emit('getError', false)
  }
}

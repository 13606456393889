































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import IconChevron from '@/app/ui/assets/expand_icon.vue'
import Options from './Options/index.vue'

export interface OptionsTypes {
  name: string
  id: string | number
}
@Component({
  components: {
    IconChevron,
    Options,
  }
})
export default class MultiOptions extends Vue {
  @Prop({ type: Array, default: () => []}) modelValue!: number[]
  @Prop({ type: Array, default: () => []}) options1!: OptionsTypes[]
  @Prop({ type: Array, default: () => []}) options2!: OptionsTypes[]
  @Prop({ type: Array, default: () => []}) options3!: OptionsTypes[]
  @Prop({ type: Number, default: 3 }) totalColumn!: number
  @Prop({ type: Boolean, default: false}) isError!: boolean
  @Prop({ type: String, default: ''}) errorMessage!: string
  @Prop({ type: Array, default: () => []}) loadings!: boolean[]
  @Prop({ type: String, default: 'Choose Category' }) placeholder!: string

  showOptions = false;
  timeoutScroll = 0;

  private _getLabel(separated: string, optionData: OptionsTypes[], level: number): string {
    const option = optionData.find(
      (item) => this.modelValue[level] === item.id
    )
    return option && option.name ? separated + option.name : ''
  }

  get valueMultiOptions(): string {
    const labelLv1 = this._getLabel('', this.options1, 0)
      const labelLv2 = this._getLabel(' > ', this.options2, 1)
      const labelLv3 = this._getLabel(' > ', this.options3, 2)
      return labelLv1 + labelLv2 + labelLv3
  }

  private scrollToSection(levelCategory: number, idOption: number): void {
    const wrapper = document.getElementById(`options-${levelCategory}`)
    const targetSection = document.getElementById(
      `options-${levelCategory}-${idOption}`
    )
    if (targetSection && wrapper) {
      wrapper.scrollTo({
        top: targetSection.offsetTop - 12,
        behavior: 'smooth',
      })
    }
  }

  private autoScrollToTarget(): void {
    for (let i = 0; i < this.totalColumn; i++) {
      this.timeoutScroll = setTimeout(() => {
        this.scrollToSection(i + 1, this.modelValue[i])
      }, 100)
    }
  }

  private onSelectOptions(idOption: number, levelCategory: number, index: number): void {
    this.$emit('on-select-options', idOption, index)
    this.scrollToSection(levelCategory, idOption)
  }

  private onShowOptions(): void {
    if (
      this.modelValue &&
      (this.modelValue.length === this.totalColumn ||
        this.modelValue.length === 0)
    ) {
      this.showOptions = !this.showOptions
      if (this.showOptions) {
        this.autoScrollToTarget()
      }
    } else {
      this.showOptions = true
    }
  }

  @Watch('modelValue', { deep: true })
  onModelValueChanged(newValue: OptionsTypes[]): void {
    if (newValue && newValue.length === this.totalColumn) {
      this.showOptions = false
    }
  }

  beforeDestroy(): void {
    if (this.timeoutScroll) {
      clearTimeout(this.timeoutScroll)
    }
  }
}

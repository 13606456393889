





















import { Component, Prop, Vue } from 'vue-property-decorator'
import EmptyPaket from '@/app/ui/assets/EmptyState/no_data-paket.vue'
import PostedIcon from '@/app/ui/assets/posted_icon.vue'
import SearchNotFoundIcon from '@/app/ui/assets/search_not_found_icon.vue'
import IllustrationEmptyState from '@/app/ui/assets/ill_spot_miscellaneous.vue'
import IllustrationProofDelivery from '@/app/ui/assets/ill_spot_proof_delivery.vue'
import IllustrationPackageNotFound from '@/app/ui/assets/ill_spot_package_not_found.vue'
import IllustrationFilterNotFound from '@/app/ui/assets/ill_spot_filter_not_found.vue'

@Component({
  components: {
    EmptyPaket,
    PostedIcon,
    SearchNotFoundIcon,
    IllustrationEmptyState,
    IllustrationProofDelivery,
    IllustrationPackageNotFound,
    IllustrationFilterNotFound
  }
})
export default class EmptyState extends Vue {
  @Prop({ type: String, default: 'empty' }) private type!: string
  @Prop({ type: String, default: '' }) private text!: string
  @Prop({ type: String, default: '' }) private description!: string
  @Prop({ type: String, default: '' }) private customClass!: string
  @Prop({ type: String, default: '' }) private classText!: string
  @Prop({ type: String, default: '' }) private classDesc!: string
}

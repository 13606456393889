import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import {
  CreateProductRequest,
  UpdateProductRequest,
  UpdateProductStatusRequest,
  UploadFileProductBulkRequest,
} from '@/data/payload/api/ProductRequest'
import { ProductPresenter } from '@/app/ui/presenters/ProductPresenter'
import {
  HistoryLogBulkProducts,
  Product,
  Products,
  VariantList,
  VariantProducts,
  ProductCategory
} from '@/domain/entities/Product'
import { Pagination } from '@/domain/entities/Pagination'
import { PRODUCT_PAGINATION } from '@/app/infrastructures/misc/Constants/pagination'
import { FormVariantTypes } from '@/app/ui/views/BisaBelanja/Merchant/Product/useCase'
import { INIT_HEADER_TABLE_VARIANT } from '@/app/infrastructures/misc/Constants/product'
import { ProductVariantRequest } from '@/data/payload/contracts/ProductRequest'

export interface ProductState {
  isLoading: boolean
  paginationData: Pagination
  productData: Product[]
  variantProduct: VariantProducts
  productDetail: Product
  statusUpload: EnumStatusUpload
  historyLogBulkProduct: HistoryLogBulkProducts
  formVariant: FormVariantTypes[]
  headerTableVariant: string[]
  dataListTable: Array<Array<string | number | boolean | undefined>>
  optionsCategoryOne: ProductCategory[]
  optionsCategoryTwo: ProductCategory[]
  optionsCategoryThree: ProductCategory[]
  errorFetch: string
}

interface ProductForm {
  name: string
  merchantId: number
  salesPrice: number | null
  basePrice: number | null
  images: Array<Blob | null>
  stock: number | null
  weight: number | null
  length: number | null
  width: number | null
  height: number | null
  description: string
  information: string
  sku: string
  variantList?: ProductVariantRequest[]
  category: number[],
}

interface EditProductForm extends ProductForm {
  id: number
  deletedImages: number[]
  deletedVariantIds?: number[]
}

export interface ProductStatusForm {
  id: number
  isActive: boolean
}

export interface ParamID {
  idOne: number
  idTwo?: number
}

export enum EnumStatusUpload {
  UPLOADING = 'Uploading',
  PROCESSING = 'Processing',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
}

export const productHasCategory = (product: Product): boolean => {
  const hasID = [
    product.productTypeId,
    product.secondProductTypeId,
    product.thirdProductTypeId,
  ].every((val) => val && val > 0)

  const hasName = [
    product.productTypeName,
    product.secondProductTypeName,
    product.thirdProductTypeName,
  ].every((val) => val && val !== '')
  return hasID && hasName
}

@Module({ namespaced: true, store, name: 'product', dynamic: true })
class ProductController extends VuexModule implements ProductState {
  private presenter: ProductPresenter = container.resolve(ProductPresenter)
  public isLoading = false
  public paginationData = new Pagination(1, PRODUCT_PAGINATION)
  public productData: Product[] = []
  public variantProduct: VariantProducts = new VariantProducts()
  public productDetail = new Product()
  public statusUpload = EnumStatusUpload.COMPLETE
  public historyLogBulkProduct = new HistoryLogBulkProducts()
  public optionsCategoryOne: ProductCategory[] = []
  public optionsCategoryTwo: ProductCategory[] = []
  public optionsCategoryThree: ProductCategory[] = []
  public errorFetch = ''
  public statusCreateUpdateProduct = ''
  public statusDeleteProduct = ''
  public statusUpdateStatusProduct = ''
  public statusUpdateProductVariantStatus = ''

  // Product Variant
  public formVariant: FormVariantTypes[] = []
  public headerTableVariant: string[] = []
  public dataListTable: Array<Array<string | number | boolean | undefined>> = []

  @Action({ rawError: true })
  public createProduct(form: ProductForm): void {
    this.setLoading(true)

    this.presenter
      .create(
        new CreateProductRequest(
          form.merchantId,
          form.name,
          <number>form.salesPrice,
          <number>form.basePrice,
          form.images,
          <number>form.stock,
          <number>form.weight,
          !form.length ? 0 : <number>form.length,
          !form.width ? 0 : <number>form.width,
          !form.height ? 0 : <number>form.height,
          form.description,
          form.information,
          form.sku,
          form.variantList,
          form.category
        )
      )
      .then(() => {
        this.setStatusCreateUpdateProduct(EventBusConstants.CREATE_PRODUCT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Create Product Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateProduct(form: EditProductForm): void {
    this.setLoading(true)

    this.presenter
      .update(
        form.id,
        new UpdateProductRequest(
          form.merchantId,
          form.name,
          <number>form.salesPrice,
          <number>form.basePrice,
          form.images,
          <number>form.stock,
          <number>form.weight,
          <number>form.length,
          <number>form.width,
          <number>form.height,
          form.description,
          form.information,
          form.deletedImages,
          form.sku,
          form.variantList,
          form.deletedVariantIds,
          form.category
        )
      )
      .then(() => {
        this.setStatusCreateUpdateProduct(EventBusConstants.UPDATE_PRODUCT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Product Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteProduct(id: number): void {
    this.setLoading(true)

    this.presenter
      .delete(id)
      .then(() => {
        this.setStatusDeleteProduct(EventBusConstants.DELETE_PRODUCT_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: `Delete Product Failed`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getProductList(params: {
    merchantId: number
    perPage?: number
    page?: number
    productName?: string
  }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setProductData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Product Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getVariantList(id: number): void {
    this.setVariantProduct(new VariantProducts(Number(id), [], 0, true))

    this.presenter
      .getVariantList(id)
      .then(res => {
        const { id, listVariant, totalActive } = res
        this.setVariantProduct(
          new VariantProducts(id, listVariant, totalActive, false)
        )
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Variant Product Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setVariantProduct(new VariantProducts(Number(id), [], 0, false))
      })
  }

  @Action( {rawError: true })
  public getCategoryLevelOne(): void {
    this.setErrorFetch('')
    this.setCategoryOne([])
    this.presenter
      .getCategoryLevelOne()
      .then((val: ProductCategory[]) => {
        this.setCategoryOne(val)
      })
      .catch((err) => {
        this.setErrorFetch(err.error.message.en)
        this.setCategoryOne([])
      })
  }

  @Action( {rawError: true })
  private _getCategoryLevelTwo(id: number): void {
    this.setErrorFetch('')
    this.setCategoryTwo([])
    this.presenter
      .getCategoryLevelTwo(id)
      .then((val: ProductCategory[]) => {
        this.setCategoryTwo(val)
      })
      .catch((err) => {
        this.setErrorFetch(err.error.message.en)
        this.setCategoryTwo([])
      })
  }

  @Action( {rawError: true })
  private _getCategoryLevelThree(paramID: ParamID): void {
    this.setErrorFetch('')
    this.setCategoryThree([])
    this.presenter
      .getCategoryLevelThree(paramID.idOne, <number>paramID.idTwo)
      .then((val: ProductCategory[]) => {
        this.setCategoryThree(val)
      })
      .catch((err) => {
        this.setErrorFetch(err.error.message.en)
        this.setCategoryThree([])
      })
  }

  @Action( {rawError: true })
  public getCategories(paramID: ParamID ): void {
    if (!paramID.idTwo) {
      this.setCategoryThree([])
      this._getCategoryLevelTwo(paramID.idOne)
    }

    if (paramID.idTwo) {
      this._getCategoryLevelThree({idOne: paramID.idOne, idTwo: paramID.idTwo})
    }
  }

  @Action( {rawError: true })
  public getDetailAndCategories(productId: string): void {
    this.setLoading(true)
    this.setErrorFetch('')
    this.setCategoryOne([])
    this.setCategoryTwo([])
    this.setCategoryThree([])
    this.presenter
    .get(productId)
    .then((val: Product) => {
      this.setProductDetail(val)
      const fetchCategory = [this.presenter.getCategoryLevelOne()]
      if (productHasCategory(val)) {
        const fetchAnotherCategory = [
          this.presenter.getCategoryLevelTwo(<number>val.productTypeId),
          this.presenter.getCategoryLevelThree(
            <number>val.productTypeId,
            <number>val.secondProductTypeId
          ),
        ]
        fetchCategory.push(...fetchAnotherCategory)
      }

      return Promise.all(fetchCategory)
    })
    .then((valCategory: ProductCategory[][]) => {
      this.setLoading(false)
      if (valCategory && valCategory.length === 3) {
        this.setCategoryOne(valCategory[0])
        this.setCategoryTwo(valCategory[1])
        this.setCategoryThree(valCategory[2])
      } else {
        this.setCategoryOne(valCategory[0])
      }
    })
    .catch((err) => {
      this.setLoading(false)
      this.setErrorFetch(err.error.message.en)
      this.setCategoryOne([])
      this.setCategoryTwo([])
      this.setCategoryThree([])
    })
}

  @Action({ rawError: true })
  public getProductDetail(id: string): void {
    this.setLoading(true)
    this.presenter
      .get(id)
      .then(res => {
        this.setProductDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Product Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updateProductStatus(form: ProductStatusForm): void {
    this.presenter
      .setStatus(form.id, new UpdateProductStatusRequest(form.isActive))
      .then(() => {
        this.setStatusUpdateStatusProduct(EventBusConstants.UPDATE_STATUS_PRODUCT_SUCCESS)
      })
      .catch(error => {
        if (
          error.status === 422 &&
          error.error.message.en.includes('has been')
        ) {
          this.setStatusUpdateStatusProduct(EventBusConstants.UPDATE_STATUS_PRODUCT_SUCCESS)
        } else {
          Vue.notify({
            title: `Update Status Product Failed`,
            text: [422, 400, 404].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
  }

  @Action({ rawError: true })
  public updateVariantStatus(form: ProductStatusForm): void {
    this.setLoading(true)

    this.presenter
      .setStatusVariant(form.id, new UpdateProductStatusRequest(form.isActive))
      .then(() => {
        this.setStatusUpdateStatusProductVariant(EventBusConstants.UPDATE_STATUS_PRODUCT_VARIANT_SUCCESS)
      })
      .catch(error => {
        if (
          error.status === 422 &&
          error.error.message.en.includes('has been')
        ) {
          this.setStatusUpdateStatusProductVariant(EventBusConstants.UPDATE_STATUS_PRODUCT_VARIANT_SUCCESS)
        } else {
          Vue.notify({
            title: `Update Status Product Failed`,
            text: [422, 400, 404].includes(error.status)
              ? error.error.message.en
              : 'Something wrong',
            type: 'error',
            duration: 5000,
          })
        }
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public uploadProductBulk(payload: {
    file: File
    params: {
      page?: number
      perPage?: number
    }
  }): void {
    this.setStatusUpload(EnumStatusUpload.UPLOADING)
    this.presenter
      .uploadProductBulk(new UploadFileProductBulkRequest(payload.file))
      .then(() => {
        this.setStatusUpload(EnumStatusUpload.PROCESSING)
        this.getHistoryLogBulkProduct(payload.params)
      })
      .catch(error => {
        Vue.notify({
          title: 'Bulk Upload Product Failed',
          text: [422, 400, 404].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setStatusUpload(EnumStatusUpload.FAILED)
      })
  }

  @Action({ rawError: true })
  public getHistoryLogBulkProduct(params: { page?: number; perPage?: number }): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistoryLog(formattedParams)
      .then(res => {
        // Set Status Log
        if (res.pagination && res.pagination.page === 1) {
          if (res.data && res.data.length > 0) {
            const statusLog = <EnumStatusUpload>res.data[0].status
            this.setStatusUpload(statusLog)
          }
        }
        this.setHistoryLogBulkProduct(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'History Log Upload Product Bulk Failed',
          text: [422, 400, 404].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setProductData(products: Products): void {
    this.paginationData = <Pagination>products.pagination
    this.productData = <Product[]>products.data
  }

  @Mutation
  private setVariantProduct(data: VariantProducts): void {
    this.variantProduct = data
  }

  @Mutation
  private setProductDetail(product: Product): void {
    this.productDetail = product
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setStatusUpload(status: EnumStatusUpload): void {
    this.statusUpload = status
  }

  @Mutation
  private setHistoryLogBulkProduct(data: HistoryLogBulkProducts): void {
    this.historyLogBulkProduct = data
    this.paginationData = <Pagination>data.pagination
  }

  @Mutation
  public setAddVariant(variant: FormVariantTypes): void {
    this.formVariant.push(variant)
  }

  @Mutation
  public setVariant(variantList: VariantList[]): void {
    const newVariantType: FormVariantTypes[] = []
    const newHeader: string[] = []
    for (const k of variantList) {
      newHeader.push(k.key)
      newVariantType.push({
        name: k.key,
        value: k.value,
        onHeader: true,
      })
    }
    this.headerTableVariant = [...newHeader, ...INIT_HEADER_TABLE_VARIANT]
    this.formVariant = newVariantType
  }

  @Mutation
  public removeOneVariant(index: number): void {
    this.formVariant.splice(index, 1)
  }

  @Mutation
  public clearAllVariant(): void {
    this.formVariant = []
  }

  @Mutation
  public setFieldTableVariant(data: {
    val: string | number | boolean
    index: number
    indexField: number
  }): void {
    const { val, index, indexField } = data
    this.dataListTable[index][indexField] = val
  }

  @Mutation
  public setHeaderTableVariant(data: string[]): void {
    this.headerTableVariant = data
  }

  @Mutation
  public setDataListTable(
    data: Array<Array<string | number | boolean | undefined>>
  ): void {
    this.dataListTable = data
  }

  @Mutation
  public setCategoryOne(data: ProductCategory[]): void {
    this.optionsCategoryOne = data
  }

  @Mutation
  public setCategoryTwo(data: ProductCategory[]): void {
    this.optionsCategoryTwo = data
  }

  @Mutation
  public setCategoryThree(data: ProductCategory[]): void {
    this.optionsCategoryThree = data
  }

  @Mutation
  public setErrorFetch(err: string): void {
    this.errorFetch = err
  }

  @Mutation
  public setStatusCreateUpdateProduct(status: string): void {
    this.statusCreateUpdateProduct = status
  }

  @Mutation
  public setStatusDeleteProduct(status: string): void {
    this.statusDeleteProduct = status
  }

  @Mutation
  public setStatusUpdateStatusProduct(status: string): void {
    this.statusUpdateStatusProduct = status
  }

  @Mutation
  public setStatusUpdateStatusProductVariant(status: string): void {
    this.statusUpdateProductVariantStatus = status
  }
}

export default getModule(ProductController)

































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { EnumForm } from '@/app/infrastructures/misc/Constants/product'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Toggle from '@/app/ui/components/Toggle/index.vue'
import ProductTextInput from '../ProductTextInput/index.vue'
import { DataObject, HeaderObject } from '@/app/ui/components/DataTableV2/type'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'

interface CustomStyle {
  minWidth: string
  maxWidth: string
}

interface DataExpand {
  customStyle: CustomStyle
  value: number | boolean
}

interface PropsTableData {
  data: DataExpand
  id: number
  index: number
  indexField: number
}

@Component({
  components: {
    DataTableV2,
    ProductTextInput,
    Toggle,
    EmptyState,
  },
})
export default class ProductTableCollapse extends Vue {
  @Prop({ type: Array, required: true }) private headers!: Array<string>
  @Prop({ type: Array, required: true }) private dataItems!: Array<string[]>
  @Prop({ type: Array, required: true }) private listError!: Array<boolean[]>
  @Prop({ type: Boolean, default: false }) private isDisabled!: boolean
  @Prop({ type: Boolean, default: false }) private isValidate!: boolean
  @Prop({ type: String, default: EnumForm.CREATE }) private formName!: string

  requiredValue = ['Price', 'Stock', 'Weight (gram)']

  get dataListTable(): DataObject[] {
    let listTable = <DataObject[]>[]
    this.dataItems.forEach(item => {
      const result = item.map(val => ({
        value: val,
        customStyle: { minWidth: '160px', maxWidth: '160px' },
      }))
      listTable.push(<DataObject>result)
    })
    return listTable
  }

  get customName(): string[] {
    return ['', ...this.headers]
  }

  get thValue(): HeaderObject[] {
    const newHeader: HeaderObject[] = []
    this.headers.forEach(v => {
      if (!v || v === '') {
        newHeader.push({ title: '-' })
      } else {
        newHeader.push({
          title: v,
          customStyle: { minWidth: '160px', maxWidth: '160px' },
        })
      }
    })
    return newHeader
  }

  get slotVariantName1(): string {
    return this.headers[0].toLowerCase().split(' ').join('-').replace(/[^a-zA-Z0-9-]+/g, '')
  }

  get slotVariantName2(): string {
    return this.headers[1].toLowerCase().split(' ').join('-').replace(/[^a-zA-Z0-9-]+/g, '')
  }

  private cellHasError(index: number, indexField: number, field?: string) {
    let cellError = {
      error: this.listError[index][indexField] && this.isValidate,
      message: 'Required: Min 1'
    }
    if (this.formName === EnumForm.EDIT) {
      if (field === 'stock') {
        cellError = {
          error: this.listError[index][indexField] && this.isValidate,
          message: 'Required: Min 0'
        }
      }
    }
    return cellError
  }

  private rowHasError(index: number, indexField: number, field?: string): boolean {
    const cellHasError = this.cellHasError(index, indexField, field).error
    if (cellHasError) {
      return false
    } else {
      return this.listError[index].includes(true) && this.isValidate
    }
  }

  private onInputField(
    val: string | undefined | number,
    propsData: PropsTableData
  ) {
    this.$emit('on-change-table', val, propsData)
  }

  private onSetStatusVariant(propsData: PropsTableData) {
    const isActive = propsData.data.value
    this.$emit('set-status-variant', isActive, propsData)
  }
}
